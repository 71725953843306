.cstmcrd {
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 1px #cecece;
  flex:0 0 23%;
  /* margin: 0 10px 15px; */
  display: flex;
  flex-direction: column;
  position: relative;
  width: 23%;
  border: 1px solid transparent;
  /* @media (max-width: 1280px) {
    width: 23%;
    flex: 0 0 23%;
  } */
  @media (max-width: 1199px) {
    width: 30.33%;
    flex: 0 0 30.33%;
  }
  @media (max-width: 991px) {
    width: 47%;
    flex: 0 0 47%;
  }
  @media (max-width: 767px) {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0;
  }
  &:hover {
    border: 1px solid #242d49;
  }
}
.cstmcrd img {
  width: 100%;
  border-radius: 10px;
}
/* .cstmcrd p:nth-child(3) {
  margin-bottom: 0;
  text-transform: capitalize;
  font-family: var(--fontFamilyJosefin);
  font-size: 18px;
  font-weight: 600;
  color: #292929;
  line-height: 24px;
  @media (max-width: 1280px) {
    word-break: break-all;
    margin-bottom: 10px;
  }
} */
.titleRow {
  margin-bottom: 0;
  text-transform: capitalize;
  font-family: var(--fontFamilyJosefin);
  font-size: 18px;
  font-weight: 600;
  color: #292929;
  line-height: 24px;
  @media (max-width: 1280px) {
    word-break: break-all;
    margin-bottom: 10px;
  }
  @media(max-width:575px){
    margin-bottom:0;
  }
}
/* .cstmcrd p:nth-child(4) {
  margin-top: 0;
  font-family: var(--fontFamilyJosefin);
  color: #292929;
  line-height: 22px;
  font-size: 15px;
  font-weight: 400;
} */
.addressLine {
  margin-top: 0;
  font-family: var(--fontFamilyJosefin);
  color: #292929;
  line-height: 22px;
  font-size: 15px;
  font-weight: 400;
}
.cstmcrd span {
  display: block;
  color: #292929;
  font-weight: 700;
  font-family: var(--fontFamilyJosefin);
  margin-top: auto;
}
/* .cstmcrd button {
    border: 0;
    color: #025196;
    cursor: pointer;
    padding: 0;
    font-size: 15px;
    font-weight: 600;
    font-family: var(--fontFamilyJosefin);
    margin-right: auto;
    &:hover {
      text-decoration: underline;
    }
  } */
.tagspan {
  position: absolute;
  right: 15px;
  background: #a2a2a2;
  border-top-right-radius: 10px;
  padding: 0px 10px;
  font-size: 13px;
  line-height: 28px;
  display: flex !important;
  align-items: center;
  font-weight: 400 !important;
  color: #fff !important;
  cursor: pointer;
}
.tagspan svg {
  width: 17px;
  height: 17px;
  margin-right: 5px;
  fill: #fdb338;
}
.buttonDetail {
  background-color: #023462;
  color: #fffffd;
  width: 100%;
  font-size: 14px;
  border-radius: 8px;
  margin: 0;
  border: 0;
  padding: 5px 15px;
  cursor: pointer;
  &:hover {
    background-color: #000;
  }
  /* & span{
      width: 100%;
    } */
}
.verif {
  border-bottom-right-radius: 10px;
  position: absolute;
  right: 15px;
  background: #a2a2a2;
  top: 207px;
  padding: 0px 10px;
  font-size: 13px;
  line-height: 28px;
  display: flex !important;
  align-items: center;
  font-weight: 400 !important;
  color: #fff !important;
}
.verif svg {
  width: 17px;
  height: 17px;
  margin-right: 5px;
  background: #fff;
  border-radius: 50%;
  fill: #4db595;
}

.price {
  font-size: 18px;
  font-weight: 800;
  color: #000;
  margin: 0 0 10px 0;
  display: block;
}
.titleRow {
  display: flex;
  flex-direction: column;
  & > span {
    /* margin-left: auto; */
    margin-top: -5px;
    margin-bottom: 10px;
    & > svg {
      height: 12px;
      width: 15px;
    }
  }
}
