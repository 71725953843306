@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCardsMapVariant {
  padding: 0 0 96px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.listingCards {
  padding: 0 0 50px 0;

  display: flex;
  /* grid-template-columns: repeat(1, 1fr);
  gap: 24px; */
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* @media(max-width:1280px){
    justify-content:center;
  } */
  @media(max-width:767px){
    padding:25px 15px;
  }
  /* @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  } */
  @media (max-width: 575px) {
    justify-content: center;
    padding:25px 15px 0;
  }
  /* @media (--viewportMedium) {
    padding: 0 0 96px 0;
    grid-template-columns: repeat(2, 1fr);
  } */

  /* @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  } */

  /* @media (min-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  } */

  /* @media (--viewportXLarge) {
    grid-template-columns: repeat(5, 1fr);
  } */
}

.listingCard {
  width: 100%;
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: transparent;
  @media(max-width:767px){
    margin-bottom:0 !important;
  }
}
.cardRootClassname {
  /* flex-basis: 30%;
  max-width: 30%; */
  width: 320px;
  margin-bottom: 10px !important;
  height: 500px;
  /* @media (max-width: 991px){
    flex-basis: 45%;
  max-width: 45%;
  }
  @media (max-width: 575px){
    flex-basis: 80%;
  max-width: 80%;
  } */
  & > div {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    & > div:first-child {
      & button {
        z-index: unset;
      }
    }
    & > div:last-child {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  @media (max-width: 767px) {
    width: 290px;
  }
}
