@import '../../../styles/customMediaQueries.css';

.root {
  display: none;

  /* Borders */
  outline: none;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  padding: 15px 30px 20px 30px;
  margin-top: 7px;
  background-color: var(--matterColorLight);

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}

.isOpenAsPopup {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.plain {
  width: 100%;

  @media (--viewportMedium) {
    padding-top: 8px;
    padding-bottom: 16px;
  }
}

.isOpen {
  display: block;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  /* max-width: 300px; */
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0px;
}
@media (--viewportMedium) {
  .contentWrapper {
    padding-top: 0px;
    padding-bottom: 8px;
    margin-top: 0px;
  }
}

.label {
  composes: h5 from global;

  font-weight: var(--fontWeightSemiBold);
  padding: 11px 0 13px 0;
  margin: 0 18px 0 0;
}
@media (--viewportMedium) {
  .label {
    padding: 8px 0 16px 0;
  }
}

.inputsWrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  width: 100%;
    @media (--viewportMedium) {
    padding-bottom: 5px;
  }
  @media (max-width:767px) {
    width: 100%;
  }
}
.mxprlbl{
  font-family: var(--fontFamilyAvenir) !important;
    position: absolute;
    top: 9px;
    z-index: 9;
    background: #F7FAFD;
    padding: 2px 4px;
    color: #B9B9B9;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.4px;
    left: 26px;
    @media (max-width:991px) {
      font-size:12px;
      /* top: -12px; */
    }
    @media(max-width:767px){
      top: 25px;
    }
}
.mnprlbl{
  font-family: var(--fontFamilyAvenir) !important;
    position: absolute;
    z-index: 9;
    bottom: 50px;
    background: #F7FAFD;
    padding: 2px 4px;
    color: #B9B9B9;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.4px;
    left: 26px;
    @media (max-width:991px) {
      font-size:12px;
      /* top: -12px; */
    }
    @media(max-width:767px){
      bottom: 40px;
    }
}
.minPrice {
  composes: marketplaceSearchFilterSublabelFontStyles from global;
  width: 48px;
  border-bottom-color: var(--attentionColor);
  border-bottom-width: 3px;
  text-align: left;
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-input-placeholder { 
    color: #B9B9B966;
    font-size:20px;
    font-family:  var(--fontFamilyAvenir) !important;
    @media (max-width:991px) {
      font-size:16px;
    }
  }

  @media (--viewportMedium) {
    padding: 1px 0 4px 0;
  }
}
.maxPrice {
  composes: marketplaceSearchFilterSublabelFontStyles from global;
  width: 48px;
  text-align: left;
  border-bottom-color: var(--attentionColor);
  border-bottom-width: 3px;

  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-input-placeholder { 
    color: #B9B9B966;
    font-size:20px;
    font-family:  var(--fontFamilyAvenir) !important;
    @media (max-width:991px) {
      font-size:16px;
    }
  }

  @media (--viewportMedium) {
    padding: 1px 0 4px 0;
  }
}

.priceInSidebar {
  /* width: 72px; */
  /* margin: 0; */
    border: none;
    border: 1px solid #023462;
    padding: 6px 24px 0 30px !important;
    position: relative;
    margin-top: 20px;
    /* max-width: 290px; */
    height: 54px;
    width: 100%;
    border-radius: 10px;
    font-size: 18px;
    @media (max-width:991px) {
      font-size:16px;
    }
    @media(max-width:767px){
      margin-top: 35px;
    }
}

.priceSeparator {
  margin: 4px 8px 0 8px;
}

@media (--viewportMedium) {
  .priceSeparator {
    margin: 2px 8px 0 8px;
  }
}

.sliderWrapper {
  display: none;
  padding: 17px 0 25px 0;
}
@media (--viewportMedium) {
  .sliderWrapper {
    padding: 16px 0 24px 0;
  }
}

.buttonsWrapper {
  display: flex;
}

.clearButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 auto 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.cancelButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.submitButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
    transition: width var(--transitionStyleButton);
  }
}
