@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.labelPlain {
  color: var(--matterColor);
}

.labelPlainSelected {
  color: var(--marketplaceColor);
}

.labelText {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field {
  composes: h4 from global;
  margin: 0;
  padding: 1px 0 13px 0;
  border: none;
}

.fieldPlain {
  margin: 0;
  border: none;
  border: 1px solid #023462;
  padding: 6px 24px 0 30px !important;
  position: relative;
  margin-top: 40px;
  /* max-width: 290px; */
  height: 54px;
  border-radius: 10px;
  @media (--viewportMedium) {
    padding: 10px 15px;
  }
}
.fieldPlain input {
  border-bottom-width: 0;
  padding-top: 7px;
  color: #292929;
  font-size: 20px;
  font-family: var(--fontFamilyAvenir) !important;
  @media (max-width: 991px) {
    font-size: 16px;
  }
  &::-webkit-input-placeholder {
    color: #b9b9b966;
    font-size: 18px;
    font-family: var(--fontFamilyAvenir) !important;
    @media (max-width: 991px) {
      font-size: 16px;
    }
  }
}
.fieldPlainLabel {
  font-family: var(--fontFamilyAvenir) !important;
  position: absolute;
  top: -11px;
  background: #f7fafd;
  padding: 2px 4px;
  color: #b9b9b9;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.4px;
  left: 26px;
  @media (max-width: 991px) {
    font-size: 12px;
    top: -12px;
  }
}

.fieldPlainLabel {
  /* padding: 4px 0 4px 0; */
}
.desktopPredictions {
  z-index: 9999;
  background: #fff;
  position: absolute;
  top: 46px;
  left: -27px;
  max-width: 125%;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 4px;
  /* background: #000; */
  @media (max-width: 767px) and (min-width: 320px) {
    width: 100% !important;
    max-width: 105%;
  }
}
.desktopPredictions ul li {
  color: #000;
}
.desktopPredictions ul li svg path {
  fill: #000;
}
.desktopInput {
  height: 40px;
}
.desktopIcon {
  display: none;
}
.desktopInputRootClass {
  display: flex;
  flex-direction: column;
}
