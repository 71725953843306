@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  margin-top: 10px;
  /* padding-left: 12px; */
  /* padding-bottom: 6px; */
  padding: 10px 0 !important ;
  border: none;
  /* max-height: 600px; */
  overflow: auto;
  @media (--viewportMedium) {
    /* padding: 3px 0 29px 12px;
    padding: 4px 0 4px 12px; */
  }
}
.mrebnssctr {
  margin: 0;
  font-weight: 700;
  color: #025196;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);
  padding: 2px 0 4px 12px;
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  left: -9px;
  line-height: 18px;
  margin-top: 8px;
  font-size: 15px;

  &:hover {
    color: #292929;
    & > span {
      text-decoration: underline;
    }
  }
}
.fieldGroupPlain ul li label {
  & > span:first-child {
    margin-right: 18px !important;
    & svg {
      width: 20px !important;
      height: 20px !important;
      @media (max-width: 991px) {
        width: 17px !important;
        height: 17px !important;
      }
    }
    /* & svg g{
        width: 20px !important;
        height: 20px !important;
      } */
  }
  & > span:last-child {
    color: #292929;
    font-size: 16px;
    font-family: var(--fontFamilyAvenir);
    /* @media (max-width:991px) {
      font-size:16px;
    } */
  }
}
.list {
  margin: 0;
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}
