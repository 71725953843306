@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.labelPlain {
  color: var(--matterColor);
}

.labelPlainSelected {
  color: var(--marketplaceColor);
}

.labelText {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field {
  composes: h4 from global;
  margin: 0;
  padding: 1px 0 13px 0;
  border: none;
}

.fieldPlain {
  composes: h4 from global;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 27px 0 4px 0px;
  border: none;

  @media (--viewportMedium) {
    padding: 27px 0 4px 0px;
  }
}

.fieldPlainLabel {
  padding: 4px 0 4px 0;
}
.desktopPredictions{
z-index: 9999;
/* background: #000; */
}
.desktopPredictions ul li{
  color: #000;
}
.desktopPredictions ul li svg path{
  fill: #000;
}
.desktopInput{
  height: 40px;
}
.desktopIcon{
display: none;
}
.desktopInputRootClass{
  display: flex;
  flex-direction: column;
}
.businessdte::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
.businessdte{
  font-size:20px;
  font-family:  var(--fontFamilyAvenir) !important;
  color: #292929;
  margin: 0;
  border: none;
  border: 1px solid #023462 !important;
  padding: 6px 24px 0 30px !important;
  position: relative;
  height: 52px;
  text-transform: uppercase;
  font-weight: 400;
  width: 100%;
  border-radius: 10px;
  display: flex;
  @media (max-width:991px) {
    font-size:16px;
  }
}
.scnd{
  margin-top: 20px !important;
  @media(max-width:767px){
    margin-top:35px !important;
  }
}
.mnprlbl{
  font-family: var(--fontFamilyAvenir) !important;
    position: absolute;
    top: 130px;
    z-index: 9;
    background: #F7FAFD;
    padding: 2px 4px;
    color: #B9B9B9;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.4px;
    left: 26px;
    @media (max-width:991px) {
      font-size:12px;
    }
    /* @media (max-width:950px) {
      top: 168px;
    } */
    @media (max-width:767px) {
      top: 146px!important;
    }
}
.mxprlbl{
  font-family: var(--fontFamilyAvenir) !important;
    position: absolute;
    top: 60px;
    z-index: 9;
    background: #F7FAFD;
    padding: 2px 4px;
    color: #B9B9B9;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.4px;
    left: 26px;
    @media (max-width:991px) {
      font-size:12px;
    }
    @media (max-width:767px) {
      top: 60px !important;
    }
    /* @media (max-width:950px) {
      top: 94px;
    } */
}
.phinp{
  color: #B9B9B966;
}