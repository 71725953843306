@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  padding-top: 5px;
  padding-bottom: 17px;
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportMedium) {
    /* padding-top: 16px; */
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.filterHeader {
  line-height: 24px;
}

.labelButton {
  /* Override button styles */
  width: 100%;
  outline: none;
  line-height: 24px;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.labelButtonContent {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #cecece;
  padding: 0 1px 10px;
  &:hover {
    color: var(--marketplaceColor);

    & * {
      color: var(--marketplaceColor);
      stroke: var(--marketplaceColor);
    }
  }
}

.labelWrapper {
  display: inline-block;
  max-width: calc(100% - 16px);
}

.label,
.labelSelected {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightBold);
  font-size: 20px;
  color: #292929 !important;
  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 8px;
  }
  @media (max-width: 991px) {
    font-size: 18px;
  }
}

.label {
  color: var(--matterColor);
}

.labelSelected {
  color: var(--marketplaceColor);
  margin-left: 5px;
}

.openSign {
  float: right;
}

.clearButton {
  composes: h5 from global;
  margin: 0;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);
  padding: 2px 0 4px 0px;
  margin-left: auto;
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }

  @media (--viewportMedium) {
    padding: 4px 0 4px 0px;
    margin-top: 8px;
  }
  @media (max-width: 767px) {
    margin-top: 10px;
  }
}

.plain {
  width: 100%;
  display: none;
  padding-bottom:5px;
  @media (--viewportMedium) {
    /* padding-bottom: 16px; */
  }
}

.isOpen {
  display: flex;
}
