@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: var(--zIndexTopbar);
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;
  background: #fffffd;
  @media (--viewportMedium) {
    position: relative;
    padding-top: var(--topbarHeightDesktop);
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
  & .lftColumn {
    flex: 0 0 25%;
    width: 25%;
    @media (max-width: 991px) {
      flex: 0 0 40%;
      width: 40%;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: 767px) {
      overflow-x: auto;
      flex: 0 0 100%;
      width: 100%;
      height: 100vh;
    }
  }
  & > div:nth-child(2) {
    flex: 0 0 75%;
    width: 75%;
    @media (max-width: 991px) {
      flex: 0 0 60%;
      width: 60%;
    }
    @media (max-width: 767px) {
      flex: 0 0 100%;
      width: 100%;
    }
  }
}

/* Container for side and content */
.layoutWrapperContainer {
  /* composes: container; */
  /* parent aka root is flexbox, this container takes all available space */
  flex-shrink: 0;
  padding: 110px 35px 30px 57px;
  /* This container uses flexbox layout */
  display: flex;
  flex-direction: row;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);
  justify-content: space-between;
  padding-bottom: 100px;
  @media (min-width: 1920px) {
    max-width: 1440px;
    margin: 0 auto;
  }
  @media (max-width: 1200px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 991px) {
    flex-direction: row;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 110px;
  }
  @media (max-width: 767px) {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    padding-bottom: 25px;
  }
}

.layoutWrapperFilterColumn {
  display: none;
  /* width: 285px; */
  /* background-color: #f7fafd; */
  /* height:100%; */
  /* padding: 20px 24px 0 24px;  */
  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    /* flex-direction: column; */
    justify-content: flex-start;
    /* padding: 24px 0 0 24px; */
  }
  @media (--viewportLarge) {
    /* Layout */
    /* padding: 40px 0 0 36px; */

    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  /* @media (min-width: 1440px) {
  }
  @media (--viewportXLarge) {
  } */
}
.mrebnssctr {
  margin: 0;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: #025196;
  font-weight: 700;
  padding: 2px 0 4px 12px;
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  left: -9px;
  line-height: 18px;
  margin-top: 8px;
  font-size: 15px;
  &:hover {
    color: #292929;
    & > span {
      text-decoration: underline;
    }
  }
}
.filterColumnContent {
  /* width: 340px; */
  /* max-width: 360px; */
  /* width: 360px; */
  padding: 20px 24px 0 24px;
  background-color: #f7fafd;

  /* background: #f7fafd; */
  /* position:sticky; */
  /* top: 130px;
  padding:0;
  height: fit-content; */
  @media (--viewportMedium) {
    padding: 20px 24px 24px 24px;
  }

  @media (--viewportLarge) {
    /* width: 320px; */
    padding: 20px 24px 24px 24px;
  }

  @media (min-width: 1440px) {
    padding: 20px 24px 24px 24px;
  }
}
.filter:first-child {
  padding-top: 0;
}

.resetAllFiltersButton,
.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
    padding: 3px 0 5px 0;
  }
}
.resetAllFiltersButton {
  color: var(--marketplaceColor);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-basis: 80%;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-top: 20px;

  @media (max-width: 767px) {
    flex-basis: unset;
    max-width: 100%;
    padding-left: 0;
    padding-top: 0;
  }
}

.noSearchResults {
  composes: h4 from global;

  /* Reserves 48px of vertical space */
  line-height: 24px;
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.mainPanel {
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }
}

.mainPanelMapVariant {
  composes: mainPanel;

  @media (--viewportMedium) {
    padding: 18px 24px 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 23px 36px 0 36px;
  }
}

.searchFiltersMobileMap {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersMobileList {
  padding: 16px 0;

  @media (--viewportMedium) {
    display: none;
  }
  @media (max-width: 767px) {
    padding: 16px 15px;
  }
  & > div:nth-child(3) {
    & > div {
      @media (max-width: 767px) {
        background-color: #f7fafd !important;
      }
      & > div {
        & > button {
          @media (max-width: 767px) {
            position: absolute !important;
            background: #fcfcfc !important;
            width: 100% !important;
            justify-content: flex-end !important;
          }
        }
      }
    }
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.listingsForMapVariant {
  composes: listings;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByDesktop {
  margin-left: auto;
}

.sortByMenuLabel {
  composes: button marketplaceTinyFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);
  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}
.selsearch {
  background: var(--marketplaceColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fffffd;
  /* max-width: 200px; */
  margin-left: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  height: 45px;
  border-radius: 5px;
  padding: 5px 10px;
  & > svg {
    flex-shrink: 0;
    & path:last-child {
      fill: #fdb338;
    }
    & path:first-child {
      fill: var(--marketplaceColor);
    }
    background: #fdb338;
    width: 23px;
    height: 23px;
    cursor: pointer;
    border-radius: 50%;
    @media (max-width: 991px) {
      width: 19px;
      height: 19px;
    }
  }
  & > span:first-child {
    margin-right: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media (max-width: 991px) {
      font-size: 13px;
    }
  }
  @media (max-width: 575px) {
    max-width: 180px;
  }
}
.seldsearchs {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  border-bottom: 1px solid #cecece;
  margin-bottom: 20px;
}
.searchBarDiv {
  display: flex;
}
.searchBar {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  /* border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative); */
  & > div {
    & > div {
      margin-left: 10px;
      & > input {
        border: 0;
      }
    }
  }
  @media (--viewportLarge) {
    padding-left: 0;
  }
  @media (max-width: 991px) {
    margin-right: 10px;
  }
  @media (min-width: 767px) {
    display: none;
  }
}
